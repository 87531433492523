import { createContext, useContext, useEffect, useRef, useState } from "react";
import {
	Modal,
	Button,
	notification,
	Upload,
	Progress,
	Table,
	Alert,
	Select,
	Flex,
	Form,
} from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUpFromBracket } from "@fortawesome/pro-regular-svg-icons";
// import * as XLSX from "xlsx";

import { POST } from "../../../../providers/useAxiosQuery";
import notificationErrors from "../../../../providers/notificationErrors";
import FacultyLoadContext from "./FacultyLoadContext";
import { apiUrl } from "../../../../providers/companyInfo";

const ModalFormFacultyLoadContext = createContext();

export default function ModalFormFacultyLoadUploadExcel() {
	const { toggleModalUploadExcel, setToggleModalUploadExcel, dataDepartments } =
		useContext(FacultyLoadContext);

	const [fileExcel, setFileExcel] = useState(null);
	const [onProgress, setOnProgress] = useState(0);
	const [onProgressStatus, setOnProgressStatus] = useState("active");
	const [onProgressMessage, setOnProgressMessage] = useState(null);

	const [toggleModalDataError, setToggleModalDataError] = useState(false);
	const [dataError, setDataError] = useState([]);
	const [userRoleId, setUserRoleId] = useState(null);
	const [schoolYearId, setSchoolYearId] = useState(null);
	const [semesterId, setSemesterId] = useState(null);

	const { mutate: mutateFormUpload, isLoading: isLoadingFormUpload } = POST(
		"api/faculty_load_upload",
		"faculty_load_list",
		true,
		(res) => {},
		setOnProgress
	);

	const handleCustomRequest = () => {
		if (fileExcel) {
			let formData = new FormData();
			formData.append("file_excel", fileExcel, fileExcel.name);
			formData.append("role", "Faculty");

			mutateFormUpload(formData, {
				onSuccess: (res) => {
					// console.log("mutateFormUpload res", res);
					if (res.success) {
						notification.success({
							message: "Faculty Load",
							description: res.message,
						});
						setToggleModalUploadExcel(false);
						setOnProgressStatus("success");
						setOnProgress(0);
						setFileExcel(null);

						let dataError = res.dataError;
						if (dataError.length > 0) {
							dataError = dataError.map((item, index) => {
								let findDepartment =
									dataDepartments &&
									dataDepartments.data.find((x) =>
										x.department_name.includes(item.parent_college)
									);

								return {
									...item,
									to_parent_college: findDepartment
										? findDepartment.department_name
										: "",
									is_change: 0,
									key: index,
								};
							});

							setToggleModalDataError(true);
							setDataError(dataError);
							setUserRoleId(res.user_role_id);
							setSchoolYearId(res.school_year_id);
							setSemesterId(res.semester_id);
						}
					} else {
						notification.error({
							message: "Faculty Load",
							description: res.message,
						});
						setOnProgressStatus("exception");
						setOnProgressMessage(res.message);
					}
				},
				onError: (err) => {
					notificationErrors(err);
					setOnProgressStatus("exception");
				},
			});
		}
	};

	return (
		<ModalFormFacultyLoadContext.Provider
			value={{
				toggleModalUploadExcel,
				setToggleModalUploadExcel,
				fileExcel,
				setFileExcel,
				onProgress,
				setOnProgress,
				onProgressStatus,
				setOnProgressStatus,
				onProgressMessage,
				setOnProgressMessage,
				isLoadingFormUpload,
				handleCustomRequest,
				toggleModalDataError,
				setToggleModalDataError,
				dataDepartments,
				dataError,
				setDataError,
				userRoleId,
				setUserRoleId,
				schoolYearId,
				setSchoolYearId,
				semesterId,
				setSemesterId,
			}}
		>
			<RenderModalFormFacultyLoadUploadExcel />
			<RenderModalDataError />
		</ModalFormFacultyLoadContext.Provider>
	);
}

const RenderModalFormFacultyLoadUploadExcel = () => {
	const {
		toggleModalUploadExcel,
		setToggleModalUploadExcel,
		setFileExcel,
		setOnProgressStatus,
		setOnProgress,
		isLoadingFormUpload,
		handleCustomRequest,
		onProgressStatus,
		onProgress,
		onProgressMessage,
		fileExcel,
	} = useContext(ModalFormFacultyLoadContext);

	const propsUpload = {
		name: "file",
		listType: "picture",
		maxCount: 1,
		multiple: false,
		showUploadList: false,
		fileList: [],
		beforeUpload: (file) => {
			const isExcel =
				file.type ===
					"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
				file.type === "application/vnd.ms-excel";

			if (!isExcel) {
				notification.error({
					message: "Faculty Load",
					description: `Format is not acceptable`,
				});
			} else {
				setFileExcel(file);
			}

			return isExcel ? true : Upload.LIST_IGNORE;
		},
	};

	return (
		<Modal
			title="UPLOAD FILE EXCEL"
			open={toggleModalUploadExcel}
			wrapClassName="modal-wrap-faculty-load-upload-excel"
			onCancel={() => {
				setToggleModalUploadExcel(false);
				setOnProgressStatus("active");
				setOnProgress(0);
				setFileExcel(null);
			}}
			footer={[
				<Button
					className="btn-main-primary outlined"
					key={0}
					onClick={() => {
						setToggleModalUploadExcel(false);
						setOnProgressStatus("active");
						setOnProgress(0);
						setFileExcel(null);
					}}
					disabled={isLoadingFormUpload}
				>
					Cancel
				</Button>,
				<Button
					className="btn-main-primary outlined"
					key={2}
					onClick={() => {
						setOnProgressStatus("active");
						setOnProgress(0);
						setFileExcel(null);
					}}
					disabled={isLoadingFormUpload}
				>
					Clear
				</Button>,
				<Button
					className="btn-main-primary"
					key={1}
					onClick={() => {
						handleCustomRequest();
					}}
					disabled={isLoadingFormUpload}
				>
					Submit
				</Button>,
			]}
		>
			<a href={apiUrl("excel-template/FacultyLoadSummaryFORMAT.xlsx")}>
				Download Faculty Load Summary FORMAT.xlsx
			</a>

			<Button
				className="upload-faculty-load"
				disabled={isLoadingFormUpload}
				style={{
					position: "relative",
				}}
			>
				{(isLoadingFormUpload || onProgressStatus === "exception") && (
					<div className="upload-faculty-load-progress">
						<Progress
							type="circle"
							percent={onProgress}
							status={onProgressStatus}
						/>
					</div>
				)}

				<Upload {...propsUpload}>
					<p className="ant-upload-drag-icon ">
						<FontAwesomeIcon icon={faArrowUpFromBracket} />
					</p>
					<p className="ant-upload-text">
						Click or drag file to this area to upload
					</p>
					<p className="ant-upload-hint">
						Support for a single upload. Strictly prohibited from uploading
						banned files or other file type.
					</p>
				</Upload>
			</Button>

			{(isLoadingFormUpload || onProgressStatus === "exception") && (
				<Alert
					className="loading-wrapper"
					message={
						onProgressMessage ? onProgressMessage : "Uploading, please wait"
					}
					type={
						!onProgressMessage && onProgressStatus !== "exception"
							? "info"
							: "error"
					}
				/>
			)}

			{fileExcel && (
				<div className="upload-faculty-load-file">{fileExcel.name}</div>
			)}
		</Modal>
	);
};

const RenderModalDataError = () => {
	const {
		toggleModalDataError,
		setToggleModalDataError,
		dataError,
		setDataError,
		userRoleId,
		setUserRoleId,
		schoolYearId,
		setSchoolYearId,
		semesterId,
		setSemesterId,
	} = useContext(ModalFormFacultyLoadContext);

	const {
		mutate: mutateFormUpdateDataError,
		isLoading: isLoadingFormUpdateDataError,
	} = POST(
		"api/faculty_load_update_data_error",
		"faculty_load_list",
		true,
		(res) => {}
	);

	const handleFinishUpdate = () => {
		let data = {
			data: dataError,
			user_role_id: userRoleId,
			school_year_id: schoolYearId,
			semester_id: semesterId,
		};
		mutateFormUpdateDataError(data, {
			onSuccess: (res) => {
				// console.log("mutateFormUpload res", res);
				if (res.success) {
					notification.success({
						message: "Faculty Load",
						description: res.message,
					});
					setToggleModalDataError(false);
					setDataError([]);
					setUserRoleId(null);
					setSchoolYearId(null);
					setSemesterId(null);
				} else {
					notification.error({
						message: "Faculty Load",
						description: res.message,
					});
				}
			},
			onError: (err) => {
				notificationErrors(err);
			},
		});
	};

	return (
		<Modal
			title="DATA NOT UPLOADED"
			open={toggleModalDataError}
			wrapClassName="modal-wrap-faculty-load-upload-excel uploaded-data-error"
			onCancel={() => {
				setToggleModalDataError(false);
				setDataError([]);
			}}
			footer={[
				<Button
					className="btn-main-primary outlined"
					key={0}
					onClick={() => {
						setToggleModalDataError(false);
						setDataError([]);
					}}
					disabled={isLoadingFormUpdateDataError}
				>
					Close
				</Button>,
				<Button
					className="btn-main-primary"
					key={1}
					onClick={() => handleFinishUpdate()}
					loading={isLoadingFormUpdateDataError}
				>
					Update
				</Button>,
			]}
		>
			<RenderTableDataError />
		</Modal>
	);
};

const EditableContext = createContext(null);

const EditableRow = ({ index, ...props }) => {
	const [form] = Form.useForm();
	return (
		<Form form={form} component={false}>
			<EditableContext.Provider value={form}>
				<tr {...props} />
			</EditableContext.Provider>
		</Form>
	);
};

const EditableCell = ({
	title,
	editable,
	children,
	dataIndex,
	record,
	handleSave,
	...restProps
}) => {
	const [editing, setEditing] = useState(false);
	const inputRef = useRef(null);
	const { dataDepartments } = useContext(ModalFormFacultyLoadContext);
	const form = useContext(EditableContext);
	const [toValueChange, setToValueChange] = useState(null);

	useEffect(() => {
		if (title === "Parent College") {
			form.setFieldsValue({
				[dataIndex]: record.to_parent_college,
			});
			setToValueChange(record.to_parent_college);
		}

		return () => {};
	}, [dataIndex, record, form, dataDepartments, title]);

	useEffect(() => {
		if (editing) {
			inputRef.current?.focus();
		}
	}, [editing]);

	const toggleEdit = () => {
		setEditing(!editing);
		form.setFieldsValue({
			[dataIndex]: record[dataIndex],
		});
	};

	const save = async () => {
		try {
			const values = await form.validateFields();

			let to_parent_college = values[dataIndex];

			toggleEdit();
			handleSave({
				...record,
				to_parent_college,
			});
		} catch (errInfo) {
			console.log("Save failed:", errInfo);
		}
	};

	let childNode = children;

	if (editable) {
		if (editing) {
			if (title === "Parent College") {
				let error_class = "";
				let error_message = record.messages.find((x) =>
					["Department is not found", "Department is required"].includes(x)
				);

				if (error_message) {
					error_class = "danger-bg-color";
				}

				childNode = (
					<Flex vertical={true} className={`${error_class} p-5`} gap={5}>
						<div className="white-color">FROM: {record.parent_college}</div>
						<Flex gap={5}>
							<div>TO:</div>
							<Form.Item name={dataIndex} noStyle>
								<Select
									ref={inputRef}
									onChange={save}
									options={
										dataDepartments && dataDepartments.data
											? dataDepartments.data.map((item) => ({
													label: item.department_name,
													value: item.department_name,
											  }))
											: []
									}
									onBlur={save}
								/>
							</Form.Item>
						</Flex>
					</Flex>
				);
			}
		} else {
			childNode = (
				<div
					className="editable-cell-value-wrap"
					style={{
						paddingInlineEnd: 24,
					}}
					onClick={toggleEdit}
				>
					<Flex vertical={true}>
						<Flex gap={5}>
							<span>FROM:</span> <span>{children}</span>
						</Flex>
						<Flex gap={5}>
							<span>TO:</span> <span>{toValueChange}</span>
						</Flex>
					</Flex>
				</div>
			);
		}
	}
	return <td {...restProps}>{childNode}</td>;
};

const RenderTableDataError = () => {
	const { dataError, setDataError } = useContext(ModalFormFacultyLoadContext);

	const defaultColumns = [
		{
			title: "Faculty No.",
			dataIndex: "school_id",
			key: "school_id",
		},
		{
			title: "Faculty Name",
			dataIndex: "faculty_name",
			key: "faculty_name",
		},
		{
			title: "Subject Code",
			dataIndex: "subject_code",
			key: "subject_code",
			render: (text, record) => {
				let error_class = "";
				let error_message = record.messages.find(
					(x) => x === "Subject is not found"
				);

				if (error_message) {
					error_class = "danger-bg-color";
				}

				return <div className={error_class}>{text}</div>;
			},
		},
		{
			title: "Section",
			dataIndex: "section",
			key: "section",
			render: (text, record) => {
				let error_class = "";
				let error_message = record.messages.find(
					(x) => x === "Section is not found"
				);

				if (error_message) {
					error_class = "danger-bg-color";
				}

				return <div className={error_class}>{text}</div>;
			},
		},
		{
			title: "Room No.",
			dataIndex: "room_no",
			key: "room_no",
			render: (text, record) => {
				let error_class = "";
				let error_message = record.messages.find((x) =>
					[
						"Building is not found",
						"Floor is not found",
						"Room is not found",
					].includes(x)
				);

				if (error_message) {
					error_class = "danger-bg-color";
				}

				return <div className={error_class}>{text}</div>;
			},
		},
		{
			title: "time",
			dataIndex: "time",
			key: "time",
			render: (text, record) => {
				let error_class = "";
				let error_message = record.messages.find(
					(x) => x === "Time is required"
				);

				if (error_message) {
					error_class = "danger-bg-color";
				}

				return <div className={error_class}>{text}</div>;
			},
		},
		{
			title: "Meridian",
			dataIndex: "meridian",
			key: "meridian",
		},
		{
			title: "Day",
			dataIndex: "day",
			key: "day",
			render: (text, record) => {
				let error_class = "";
				let error_message = record.messages.find(
					(x) => x === "Day is not found"
				);

				if (error_message) {
					error_class = "danger-bg-color";
				}

				return <div className={error_class}>{text}</div>;
			},
		},
		{
			title: "Type",
			dataIndex: "type",
			key: "type",
		},
		{
			title: "Parent Department",
			dataIndex: "parent_department",
			key: "parent_department",
		},
		{
			title: "Parent College",
			dataIndex: "parent_college",
			key: "parent_college",
			editable: true,
		},
	];

	const handleSave = (row) => {
		const newData = [...dataError];
		const index = newData.findIndex((item) => row.key === item.key);
		const item = newData[index];
		newData.splice(index, 1, {
			...item,
			...row,
		});
		setDataError(newData);
	};

	const components = {
		body: {
			row: EditableRow,
			cell: EditableCell,
		},
	};

	const columns = defaultColumns.map((col) => {
		if (!col.editable) {
			return col;
		}
		return {
			...col,
			onCell: (record) => ({
				record,
				editable: col.editable,
				dataIndex: col.dataIndex,
				title: col.title,
				handleSave,
			}),
		};
	});

	return (
		<Table
			components={components}
			rowClassName={() => "editable-row"}
			bordered
			rowKey={(record) => record.key}
			dataSource={dataError}
			columns={columns}
		/>
	);
};
